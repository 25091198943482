import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register";
// import { Login } from "./pages/Login";
import { Protected } from "./routes/Protected";
import { Protpage } from "./pages/Protpage";
import { Dashboard } from "./pages/Dashboard";
import { Databoard } from "./pages/Databoard";
import { Report } from "./pages/Report";
// import { Signup } from "./pages/Signup";
import { Hompage } from "./pages/Hompage";
import { useEffect } from "react";
import axios from "axios";
import { useAuth } from "./contexts/Authcontext";
import { useNavc } from "./contexts/Navcontext";
import { Registration } from "./pages/Registration";
import { Signin } from "./pages/Signin";
import { baseurl } from "./api/url";
// import { Home } from "./pages/Home";

function App() {
  const { token } = useAuth();
  const { navdispatch } = useNavc();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        const response = await axios.get(
          // "https://emissions-calculator-react.herokuapp.com/getlogo",
          `${baseurl}/getlogo`,
          {
            signal,
            headers: { authorization: `Bearer ${token}` },
          }
        );
        navdispatch({
          type: "SET_NAVBRAND",
          payload: {
            navimage: response.data.logo,
            navbrandname: response.data.name,
          },
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      }
    })();

    return () => {
      controller.abort();
    };
  }, [navdispatch, token]);
  return (
    <>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Protected>
                <Hompage />
              </Protected>
            }
          />
          <Route path="/signup" element={<Registration />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Signin />} />
          <Route
            path="/report"
            element={
              <Protected>
                <Report />
              </Protected>
            }
          />
          <Route
            path="/report/:name"
            element={
              <Protected>
                <Report />
              </Protected>
            }
          />
          <Route
            path="/visualize"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/visualization/:name"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/databoard"
            element={
              <Protected>
                <Databoard />
              </Protected>
            }
          />
          <Route
            path="/datainboard/:name"
            element={
              <Protected>
                <Databoard />
              </Protected>
            }
          />
          <Route
            path="/prot"
            element={
              <Protected>
                <Protpage />
              </Protected>
            }
          />
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;

import axios from "axios";
import { useEffect } from "react";
import { useDataboard } from "../contexts/Databoard";
import {
  datetoviewformat,
  // deleterecorde,
} from "../functions/databoardfunctions";
// import editbtn from "../assets/databoard/editbtn.svg";
// import trashbtn from "../assets/databoard/trshbtn.svg";
import { useAuth } from "../contexts/Authcontext";
import { baseurl } from "../api/url";
import { CircularProgress, Stack } from "@mui/material";

export function Databoardtablee() {
  const { databoarddispatch, databoardstate } = useDataboard();
  const { token } = useAuth();
  // async function handledeletee(deletionid) {
  //   const res = await deleterecorde(deletionid);
  //   if (res.success) {
  //     databoarddispatch({
  //       type: "DELETE_REORDE",
  //       payload: { deletionid: res.deletionid },
  //     });
  //   }
  // }
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        databoarddispatch({
          type: "LOADER_ON",
        });
        // console.log("elec loader off");
        const response = await axios.get(
          // "https://emissions-calculator-react.herokuapp.com/allElectricityFactors",
          `${baseurl}/allElectricityFactors`,
          {
            signal,
            headers: { authorization: `Bearer ${token}` },
          }
        );
        // console.log("----");
        // console.log(response.data.All);
        // console.log("----");
        databoarddispatch({
          type: "SET_ALLFACTORSE",
          payload: { factorsobjecte: response.data.All },
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      } finally {
      }
    })();

    return () => {
      controller.abort();
    };
  }, [databoarddispatch, token]);
  // https://emissions-calculator-react.herokuapp.com/electricityEmission
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        const response = await axios.get(`${baseurl}/electricityEmissions`, {
          signal,
          headers: { authorization: `Bearer ${token}` },
        });
        // console.log("********");
        // console.log(response.data);
        // console.log("********");
        databoarddispatch({
          type: "SET_VALUES_ELEC",
          payload: { databoardarraye: response.data },
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      } finally {
        databoarddispatch({
          type: "LOADER_OFF",
        });
        // console.log("elec loader off");
      }
    })();

    return () => {
      controller.abort();
    };
  }, [databoarddispatch, token]);
  // console.log(databoardstate.databoardtablee);
  return (
    <>
      {databoardstate.databoardtableloading ? (
        <h1 style={{ fontSize: "2rem", textAlign: "center" }}>
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        </h1>
      ) : (
        <table className="databoardtable__table">
          <thead className="databoardtable__tablehead">
            <tr className="databoardtable__theadtr">
              {/* <th className="databoardtable__theadth" scope="col">
                <span className="blank__letters">check</span>
              </th> */}
              <th className="databoardtable__theadth" scope="col">
                date
              </th>
              <th className="databoardtable__theadth" scope="col">
                Energy
              </th>
              <th className="databoardtable__theadth" scope="col">
                Type
              </th>
              <th className="databoardtable__theadth" scope="col">
                Source
              </th>
              <th className="databoardtable__theadth" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="databoardtable__tablebody">
            {[...databoardstate.databoardtablee].map((item) => {
              return (
                <tr key={item._id} className="databoardtable__tabletr">
                  {/* <td>
                    <div className="databoardtable__tablecheck">
                      <input type="checkbox" defaultChecked />
                    </div>
                  </td> */}
                  <td className="databoardtable__tabletd">
                    {datetoviewformat(item.date)}
                  </td>
                  <td className="databoardtable__tabletd">{item.energy} kWh</td>
                  <td className="databoardtable__tabletd">
                    {
                      [...databoardstate.factorarre].find(
                        (elem) => elem.id === JSON.stringify(item.factorType)
                      ).factor
                    }
                  </td>
                  <td className="databoardtable__tabletd">-</td>
                  <td className="databoardtable__tabletd">
                    {/* <div className="databoardtable__tableactions">
                      <button
                        onClick={() =>
                          databoarddispatch({
                            type: "SHOW_UPDATE_MODALE",
                            payload: { updationrecorde: item },
                          })
                        }
                        // onClick={() => console.log(item)}
                        className="databoardtable__tablebtn"
                      >
                        <img src={editbtn} alt="editbtn" />
                      </button>
                      <button
                        onClick={() => handledeletee(item._id)}
                        // onClick={() => console.log(item)}
                        className="databoardtable__tablebtn"
                      >
                        <img src={trashbtn} alt="trashbtn" />
                      </button>
                    </div> */}
                    coming soon
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseurl } from "../api/url";
import { useAuth } from "../contexts/Authcontext";
export function Signin() {
  const { setIsuserloggedin, setToken } = useAuth();
  //   const { isuserloggedin, setIsuserloggedin, setToken } = useAuth();
  // const [usermail, setUsermail] = useState("");
  // const [userpword, setUserpword] = useState("");
  const [loader, setLoader] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.from?.pathname || "/";
  // const buttonstatus =
  //   usermail.length > 0 && userpword.length > 0 ? false : true;
  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {},
  });
  const onSubmit = async (data) => {
    // handleLogin(data);
    try {
      setLoader(true);
      const response = await axios.post(`${baseurl}/login`, data);
      //  console.log(response);
      //  console.log(response.status);
      if (response.status === 200) {
        const stringifieddata = JSON.stringify({
          loginstatus: true,
          clienttoken: response.data.token,
        });
        localStorage.setItem("login", stringifieddata);
        setIsuserloggedin(true);
        setToken(response.data.token);
        navigate(from);
        toast.success("login sucessfull", {
          position: "top-right",
          autoClose: 1998,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      // console.log({ error });
      if (
        error.response.data.message === "Email not found" &&
        error.response.status === 404
      ) {
        toast.error("Email not found", {
          position: "top-right",
          autoClose: 1998,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        error.response.data.message === "Password does not match" &&
        error.response.status === 400
      ) {
        toast.error("Password does not match", {
          position: "top-right",
          autoClose: 1998,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("login failed", {
          position: "top-right",
          autoClose: 1998,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // console.log({ errorresponse: error.response.data.message });
    }
  };
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);
  return (
    <>
      <div>
        <section className="siginpage">
          <div className="sigin__side"></div>
          <div className="sigin__container">
            <div className="sigin__head">
              <h1 className="sigin__header">Login</h1>
              <p className="sigin__message">
                Dont have an account?&nbsp;
                <Link className="link" to="/signup">
                  Signup
                </Link>
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="sigin__form">
              <div className="sigin__formonsite">
                <input
                  ref={inputRef}
                  {...register("email")}
                  required
                  className="sigin__input"
                  placeholder="email"
                  type="email"
                />
                <input
                  placeholder="password"
                  required
                  {...register("password")}
                  className="sigin__input"
                  {...register("password")}
                  type="password"
                />
                <button type="submit" className="sigin__button">
                  <div className="sigin__buttonsubcont">
                    <div>Login</div>
                    {loader && <div className="loadingspinner spinning" />}
                  </div>
                </button>
                <div className="sign__options">
                  <div className="sign__line"></div>
                  <div className="sign__or">OR</div>
                  <div className="sign__line"></div>
                </div>
                <button type="button" className="sigin__withgoogle">
                  <img
                    className="sigin__withgoogleicon"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    alt="google"
                  />
                  <span className="sigin__withgoogletext">
                    Continue with Google
                  </span>
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

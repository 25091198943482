import axios from "axios";
import { useEffect } from "react";
import { useDataboard } from "../contexts/Databoard";
import { datetoviewformat } from "../functions/databoardfunctions";
import { useAuth } from "../contexts/Authcontext";
import { baseurl } from "../api/url";
import { CircularProgress, Stack } from "@mui/material";

export function Databoardtablef() {
  const { databoarddispatch, databoardstate } = useDataboard();
  const { token } = useAuth();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        databoarddispatch({
          type: "LOADER_ON",
        });
        const response = await axios.get(`${baseurl}/fuelEmissions`, {
          signal,
          headers: { authorization: `Bearer ${token}` },
        });
        console.log(response.data);
        databoarddispatch({
          type: "SET_VALUES_FUEL",
          payload: { databoardarrayf: [...response.data] },
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      } finally {
        databoarddispatch({
          type: "LOADER_OFF",
        });
      }
    })();

    return () => {
      controller.abort();
    };
  }, [databoarddispatch, token]);
  return (
    <>
      {databoardstate.databoardtableloading ? (
        <h1 style={{ fontSize: "2rem", textAlign: "center" }}>
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        </h1>
      ) : (
        <table className="databoardtable__table">
          <thead className="databoardtable__tablehead">
            <tr className="databoardtable__theadtr">
              <th className="databoardtable__theadth" scope="col">
                date
              </th>
              <th className="databoardtable__theadth" scope="col">
                Money&nbsp;(INR)
              </th>
              <th className="databoardtable__theadth" scope="col">
                Type
              </th>
              <th className="databoardtable__theadth" scope="col">
                Source
              </th>
              <th className="databoardtable__theadth" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody></tbody>
          <tbody className="databoardtable__tablebody">
            {[...databoardstate.databoardtablef].map((item) => {
              return (
                <tr key={item._id} className="databoardtable__tabletr">
                  <td className="databoardtable__tabletd">
                    {datetoviewformat(item?.date)}
                  </td>
                  <td className="databoardtable__tabletd">{item?.money}</td>
                  <td className="databoardtable__tabletd">
                    {item?.factorType}
                  </td>
                  <td className="databoardtable__tabletd">-</td>
                  <td className="databoardtable__tabletd">coming soon</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Dashparameters } from "../components/Dashparameters";
import { Lowsootfooter } from "../components/Lowsootfooter";
import dayjs from "dayjs";
// import { Topbarv2 } from "../components/topbar/Topbarv2";
// import { Topbar } from "../components/Topbar";
import { useAuth } from "../contexts/Authcontext";
// import truck from "../assets/truck.svg";
import { useVisuals } from "../contexts/Visualcontext";
import { Sidenavv3 } from "../components/sidenav/Sidenavv3";
import { Topbarv3 } from "../components/topbar/Topbarv3";
import { baseurl } from "../api/url";
import { Bargraphblock } from "../components/summary/Bargraphblock";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Linegraphblock } from "../components/summary/Linegraphblock";
import {
  CircularProgress,
  createMuiTheme,
  CssBaseline,
  Stack,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
export function Hompage() {
  const {
    visualdispatch,
    visualstate,
    dateval,
    setDateval,
    enddateval,
    setEnddateval,
  } = useVisuals();
  const { token } = useAuth();
  const [loader, setloader] = useState(true);
  const theme = createMuiTheme({
    typography: {
      htmlFontSize: 10,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            fontSize: "62.5%",
          },
        },
      },
    },
  });
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        setloader(true);
        const response = await axios.get(
          `${baseurl}/visualisation`,
          // "https://emissions-calculator-react.herokuapp.com/visualisation",
          {
            signal,
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log({
          fuelviz: response.data.totalFuelScope,
          fuelvizs: response.data.totalFuelExpenditure,
        });
        visualdispatch({
          type: "SET_VISUALS",
          payload: {
            travelviz: response.data.Travel,
            cargoviz: response.data.Cargo,
            electriviz: response.data.Electricity,
            fuelviz: response.data.Fuel,
            scope1viz: response.data.scope1,
            scope2viz: response.data.scope2,
            scope3viz: response.data.scope3,
            toatalviz: response.data.total,
          },
        });
        visualdispatch({
          type: "SET_DASHTOTALS",
          payload: {
            totalDistanceTravelled: response.data.totalDistanceTravelled,
            totalElectricityUsage: response.data.totalElectricityUsage,
            totalFuelScope: response.data.totalFuelScope,
          },
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      } finally {
        setloader(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [visualdispatch, token]);
  return (
    <div className="dcontainer">
      <Sidenavv3 />
      <div className="homemainv2">
        <Topbarv3 />
        <div className="homemaincontentv2">
          <h1 className="homemainheaderv2">Summary</h1>
          {loader ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <Dashparameters />
              <div className="summaryparams">
                {visualstate?.totalDistanceTravelled && (
                  <div className="summaryparam">
                    <p className="summaryparam__title">
                      Total number of <br />
                      kilometers travelled
                    </p>
                    <p className="summaryparam__value">
                      {visualstate.totalDistanceTravelled} kms
                    </p>
                  </div>
                )}
                {/* {visualstate?.totalElectricityUsage && (
                  <div className="summaryparam">
                    <p className="summaryparam__title">
                      Total number of
                      <br /> electricity consumed
                    </p>
                    <p className="summaryparam__value">
                      {visualstate?.totalElectricityUsage} KWh
                    </p>
                  </div>
                )}
                {visualstate?.totalFuelScope && (
                  <div className="summaryparam">
                    <p className="summaryparam__title">
                      Total fuel
                      <br /> scope
                    </p>
                    <p className="summaryparam__value">
                      {Number(visualstate.totalFuelScope).toFixed(1)} units
                    </p>
                  </div>
                )} */}
              </div>
              <div className="datefilters">
                <h3 className="datefilters__head">Date Filters</h3>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      minDate="2022-1-1"
                      maxDate="2022-12-31"
                      label="Start date"
                      inputFormat="MM/DD/YYYY"
                      value={dayjs(dateval)}
                      onChange={(newValue) => {
                        const monthkey = JSON.stringify(newValue["$M"] + 1);
                        const monthkeystring =
                          monthkey.length === 1 ? `0${monthkey}` : monthkey;
                        setDateval(
                          `${newValue["$y"]}-${monthkeystring}-${newValue["$D"]}`
                        );
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DesktopDatePicker
                      minDate="2022-1-1"
                      maxDate="2022-12-31"
                      label="End date"
                      inputFormat="MM/DD/YYYY"
                      value={dayjs(enddateval)}
                      onChange={(newValue) => {
                        const monthkey = JSON.stringify(newValue["$M"] + 1);
                        const monthkeystring =
                          monthkey.length === 1 ? `0${monthkey}` : monthkey;
                        setEnddateval(
                          `${newValue["$y"]}-${monthkeystring}-${newValue["$D"]}`
                        );
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
                {/* <label>Start date:</label>
            <input
              value={dateval}
              onChange={(e) => {
                console.log(e.target.value);
                setDateval(e.target.value);
              }}
              type="date"
              min="2022-01-01"
              max="2022-12-29"
            /> */}
                {/* <label>End date:</label>
            <input
              value={enddateval}
              onChange={(e) => {
                console.log(e.target.value);
                setEnddateval(e.target.value);
              }}
              type="date"
              min="2022-01-01"
              max="2022-12-29"
            /> */}
              </div>
              <div className="homevizgraph__cont">
                <div className="summarygrid">
                  {visualstate?.travel?.Air && (
                    <Linegraphblock
                      vizarray={visualstate.travel.Air}
                      graphname={"Air Travel"}
                    />
                  )}
                  {visualstate?.travel?.Road && (
                    <Bargraphblock
                      vizarray={visualstate.travel.Road}
                      graphname={"Road Travel"}
                    />
                  )}
                  {visualstate?.cargo?.Air && (
                    <Linegraphblock
                      vizarray={visualstate.cargo.Air}
                      graphname={"Air Cargo"}
                    />
                  )}
                  {visualstate?.cargo?.Road && (
                    <Bargraphblock
                      vizarray={visualstate.cargo.Road}
                      graphname={"Road Cargo"}
                    />
                  )}
                  {visualstate?.electricty?.Electricity && (
                    <Bargraphblock
                      vizarray={visualstate.electricty.Electricity}
                      graphname={"Electricity"}
                    />
                  )}
                  {visualstate?.fuel?.Fuel && (
                    <Bargraphblock
                      vizarray={visualstate.fuel.Fuel}
                      graphname={"Fuel"}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          <Lowsootfooter />
        </div>
      </div>
    </div>
  );
}

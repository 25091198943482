import { CircularProgress, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseurl } from "../api/url";
import { Dashgrapf } from "../components/Dashgrapf";
import { Dashgraph } from "../components/Dashgraph";
import { Dashgrapharea } from "../components/Dashgrapharea";
import { Dashgraphc } from "../components/Dashgraphc";
import { Dashgrape } from "../components/Dashgraphe";
import { Dashparameters } from "../components/Dashparameters";
import { Dashwebplatforms } from "../components/Dashwebplatforms";
import { Lowsootfooter } from "../components/Lowsootfooter";
import { Sidenavv3 } from "../components/sidenav/Sidenavv3";
// import { Sidenav } from "../components/Sidenav";
// import { Sidenavv2 } from "../components/sidenav/Sidenavv2";
// import { Sidenavvv2 } from "../components/sidenav/Sidenavvv2";
// import { Topbar } from "../components/Topbar";
// import { Topbarv2 } from "../components/topbar/Topbarv2";
import { Topbarv3 } from "../components/topbar/Topbarv3";
import { useAuth } from "../contexts/Authcontext";
// import { useNavc } from "../contexts/Navcontext";
import { useVisuals } from "../contexts/Visualcontext";

export function Dashboard() {
  // const { navboardstate } = useNavc();
  const { visualdispatch } = useVisuals();
  const [loader, setloader] = useState(true);
  const { name } = useParams();
  const { token } = useAuth();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        setloader(true);
        const response = await axios.get(
          `${baseurl}/visualisation`,
          // "https://emissions-calculator-react.herokuapp.com/visualisation",
          {
            signal,
            // headers: { authorization: authtoken },

            headers: { authorization: `Bearer ${token}` },
          }
        );
        // console.log({
        //   travelviz: response.data.Travel,
        //   cargoviz: response.data.Cargo,
        //   electriviz: response.data.Electricity,
        // });
        // console.log({ dashload: response.data });
        visualdispatch({
          type: "SET_VISUALS",
          payload: {
            travelviz: response.data.Travel,
            cargoviz: response.data.Cargo,
            electriviz: response.data.Electricity,
            fuelviz: response.data.Fuel,
            scope1viz: response.data.scope1,
            scope2viz: response.data.scope2,
            scope3viz: response.data.scope3,
            toatalviz: response.data.total,
          },
          // scope1: 8175.648
          // scope2: 1204.74
          // scope3: 29078.552053507512
          // total: 38458.94005350751
        });
        // SET_VISUALS
        // databoarddispatch({
        //   type: "SET_ALLFACTORS",
        //   payload: { factorsobject: response.data },
        // });
      } catch (error) {
        if (axios.isAxiosError(error)) {
        } else {
          // console.log({ error });
        }
      } finally {
        setloader(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [visualdispatch, token]);
  return (
    <div className="dcontainer">
      {/* <Sidenav /> */}
      {/* <Sidenavv2 /> */}
      {/* <Sidenavvv2 /> */}
      <Sidenavv3 />
      <div className="dashmain">
        {/* <Topbar /> */}
        <Topbarv3 />

        {loader ? (
          <div className="dashmaincontent">
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          </div>
        ) : (
          <div className="dashmaincontent">
            <h1 className="dashheader">Visualization</h1>
            <Dashparameters />
            {/* <div className="dashgraphs__cont">
              {navboardstate.navnow === 1 && <Dashgraph />}
              {navboardstate.navnow === 3 && <Dashgraphc />}
              {navboardstate.navnow === 2 && <Dashgrape />}
              {navboardstate.navnow === 5 && <Dashgrapf />}
            </div> */}
            <div className="dashgraphs__cont">
              {name === "travel" && <Dashgraph />}
              {name === "cargo" && <Dashgraphc />}
              {name === "electricity" && <Dashgrape />}
              {name === "fuel" && <Dashgrapf />}
            </div>
            <div className="dashgraphblock hide">
              <Dashgrapharea />
              <Dashwebplatforms />
            </div>
          </div>
        )}
        <Lowsootfooter />
      </div>
    </div>
  );
}

// import { Sidenav } from "../components/Sidenav";
// import { Topbar } from "../components/Topbar";
import { Databoardcontols } from "../components/Databoardcontols";
import { Databoardtable } from "../components/Databoardtable";
// import { Databottom } from "../components/Databottom";
import { Lowsootfooter } from "../components/Lowsootfooter";
import { Databoardtablec } from "../components/Databoardtablec";
// import { useNavc } from "../contexts/Navcontext";
// import { Databottomc } from "../components/Databottomc";
// import { Databottome } from "../components/Databottome";
// import sheetsname from "../assets/databoard/gsheetsname.svg";
// import axios from "axios";
// import { useDataboard } from "../contexts/Databoard";
// import { useEffect } from "react";

// import { Sidenavv2 } from "../components/sidenav/Sidenavv2";
import { Databoardtablee } from "../components/Databoardtablee";
// import { Sidenavvv2 } from "../components/sidenav/Sidenavvv2";
import { useDataboard } from "../contexts/Databoard";
// import { useState } from "react";
// import { Addmodalc } from "../components/Addmodalc";
import { Datainmodal } from "../components/Datainmodal";
// import { Topbarv2 } from "../components/topbar/Topbarv2";
import { Sidenavv3 } from "../components/sidenav/Sidenavv3";
import { Topbarv3 } from "../components/topbar/Topbarv3";
import { Databoardtablef } from "../components/Databoardtablef";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
export function Databoard() {
  // const { navboardstate } = useNavc();
  const { name } = useParams();
  const { databoardstate, databoarddispatch } = useDataboard();
  // const [state, setState] = useState(false);
  return (
    <>
      <div className="dcontainer">
        {/* <Sidenav /> */}
        {/* <Sidenavv2 /> */}
        <Sidenavv3 />
        <div className="datamain">
          {/* <Topbar /> */}
          <Topbarv3 />

          <div className="databoardmaincontent">
            <h1 className="databoardheader">Data-in Board</h1>
            <div className="databoardupoptions">
              <div className="databoardupoption">
                <button
                  onClick={() =>
                    databoarddispatch({ type: "SHOW_SHEETS_MODAL" })
                  }
                  className="databoardupoption_btn"
                >
                  <span className="databoardupoption_btntxt">connect</span>
                  <img
                    className="databoardupoption_btnlogo"
                    src="https://images.ctfassets.net/k49d63tr8kcn/5bCNO2Pj0vuoV0cifCYniN/1c85fd1fa4101a7908d1bb78820330e2/google-sheets-logo-color.svg"
                    alt="Google_Sheets"
                  />
                </button>
              </div>
            </div>
            <div className="databoardtablecont">
              <div className="databoardtabletop">
                {name === "travel" && (
                  <div>
                    <h2 className="databoardtabletop__header">Travel</h2>
                    {!databoardstate.databoardtableloading && (
                      <p className="databoardtabletop__norows">
                        {databoardstate.databoardtable.length} results found
                      </p>
                    )}
                  </div>
                )}
                {name === "electricity" && (
                  <div>
                    <h2 className="databoardtabletop__header">Electricity</h2>
                    {!databoardstate.databoardtableloading && (
                      <p className="databoardtabletop__norows">
                        {databoardstate.databoardtablee.length} results found
                      </p>
                    )}
                  </div>
                )}
                {name === "cargo" && (
                  <div>
                    <h2 className="databoardtabletop__header">Cargo</h2>
                    {!databoardstate.databoardtableloading && (
                      <p className="databoardtabletop__norows">
                        {databoardstate.databoardtablec.length} results found
                      </p>
                    )}
                  </div>
                )}
                {name === "fuel" && (
                  <div>
                    <h2 className="databoardtabletop__header">Fuel</h2>
                    {!databoardstate.databoardtableloading && (
                      <p className="databoardtabletop__norows">
                        {databoardstate.databoardtablef.length} results found
                      </p>
                    )}
                  </div>
                )}
                <div className="databoardtabletop__controls">
                  <Databoardcontols />
                </div>
              </div>
              {/* <div className="databoardtable">
                {navboardstate.navnow === 1 && <Databoardtable />}
                {navboardstate.navnow === 3 && <Databoardtablec />}
                {navboardstate.navnow === 2 && <Databoardtablee />}
                {navboardstate.navnow === 5 && <Databoardtablef />}
              </div> */}
              <div className="databoardtable">
                {name === "travel" && <Databoardtable />}
                {name === "cargo" && <Databoardtablec />}
                {name === "electricity" && <Databoardtablee />}
                {name === "fuel" && <Databoardtablef />}
              </div>
              {/* <>
                {navboardstate.navnow === 1 && <Databottom />}
                {navboardstate.navnow === 3 && <Databottomc />}
                {navboardstate.navnow === 2 && <Databottome />}
              </> */}
            </div>
          </div>
          <Lowsootfooter />
          {databoardstate.sheetsmodal && <Datainmodal />}
        </div>
      </div>
    </>
  );
}
